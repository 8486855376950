import ReactGA from "react-ga";

export function getAllUrlParams(url) {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      // paramName = paramName.toLowerCase();
      // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}


export function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop

    window.scrollTo({
      top: item - mainNavHeight,
      behavior: "smooth",
    })
  }

  return true
}

export function scrollTo(hash) {
  if (typeof window !== "undefined") {
    document.querySelector(`#${hash}`).scrollIntoView()
  }
}

export const initializeAnalytics = () => {
  if(window.location.hostname === "lucaplus.com"){
    ReactGA.initialize('UA-141695607-4');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

export const getMailChimpEndPoint = () => {
  let mail_chimp_endpoint = ""
  if (process.env.GATSBY_APP_URL) {
    mail_chimp_endpoint =
        `${process.env.GATSBY_APP_URL}` + "/api/v1/forms/dynamic"
  } else {
    mail_chimp_endpoint =
        "https://luca-dev.lucaplus.com/api/v1/forms/dynamic"
    if (window.location.hostname === "lucaplus.com") {
      mail_chimp_endpoint = "https://app.lucaplus.com/api/v1/forms/dynamic"
    }
  }
  return mail_chimp_endpoint
}

export const generateNSIPPromoCode = () => {
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];

  const d = new Date();
  return  "NSIP" + monthNames[d.getMonth()];
}

export const specialOfferLimits = () => {
  if((new Date().getTime() < new Date("2021-04-18").getTime()))
  {
   return true
  }
  return false
}